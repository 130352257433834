:root {
    --background-color: #000000;
    --readyswap-green: #30bd92;
    --readyswap-purple: #8360c3;
    --readyswap-grey: #181818;
  
    --font-small: .875rem;
    --font-standard: 1rem;
  }
  
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  
  * {
    margin: 0;
    padding: 0;
    font: inherit;
  }
  
  html:focus-within {
    scroll-behavior: smooth;
  }
  
  body {
    min-height: 100vh;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Montserrat, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: var(--background-color);
  }
  
  a {
    text-decoration-skip-ink: auto;
    text-decoration: none;
    color: inherit;
  }
  
  img,
  picture {
    max-width: 100%;
    display: block;
  }
  
  input,
  button,
  textarea,
  select {
    font: inherit;
  }
  
  /*DASHBOARD CSS*/
  body {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: url(./media/circle_right.png), url(./media/circle_left.png), radial-gradient(70vw 50% at 50% bottom, #5e4e975e 2%, #00bffb30 30%, #00bffb00 90%), linear-gradient(#000000 30%, black), url(./media/background_pixel.png);
    background-position: right, left;
    background-repeat: no-repeat, no-repeat, repeat, repeat;
    position: relative;
    gap: 20px;
  }
  
  .hidden {
    display: none !important;
  }
  
  .readyswap .smaller {
    color: var(--readyswap-green);
    font-weight: 400;
  }
  
  .smaller {
    font-weight: 300;
  }
  
  section {
    width: 900px;
    max-width: 95vw;
  }
  
  h1 {
    font-size: 1.125rem;
    text-align: left;
    justify-content: flex-start;
    font-weight: 600;
    color: var(--readyswap-green);
  }
  
  h2 {
    text-align: left;
    font-weight: 500;
    margin-top: 0;
    font-size: 1.125rem;
    color: white;
  }
  
  /*HEADER*/
  .header-wrapper {
    position: fixed;
    z-index: 99;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 1s !important;
    background: black;
    border-bottom: 1px solid;
    border-color: rgba(255, 255, 255, 0.06);
  }
  
  .header-background {
    background-color: black;
  }
  
  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    width: 100%;
    max-width: 95vw;
    padding: 18px 0px;
  }
  
  .header-logo-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 500;
    gap: 8px;
  }
  
  .header-logo {
    width: 40px;
  }
  
  #connect-button {
    border: none;
    background-color: var(--readyswap-green);
    font-size: var(--font-small);
    padding: 4px 8px;
    border-radius: 12px;
    font-weight: 500;
    cursor: pointer;
    color: #e8e8e8;
  }
  .wallet-adapter-button-trigger {
    border: none;
    background-color: var(--readyswap-green);
    font-size: var(--font-small);
    border-radius: 12px;
    font-weight: 500;
    cursor: pointer;
    color: #e8e8e8;
    height: 40px;
  }
  .wallet-adapter-button-trigger:not([disabled]):hover {
    background-color: var(--readyswap-green);
  }
  .wallet-adapter-dropdown-list{
    font-family: Montserrat, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background: linear-gradient(270deg, #8360c326, #30bd9217) border-box;
  }
  
  /*DASHBOARD*/
  /*SWITCH BUTTON*/
  .switch-button {
    width: 600px;
    max-width: 98vw;
    text-align: center;
    will-change: transform;
    cursor: pointer;
    -webkit-transition: .3s ease all;
    transition: .3s ease all;
    border: 1px solid white;
    border-radius: 12px;
    overflow: clip;
    font-size: var(--font-small);
    font-weight: 500;
    justify-content: space-between;
  
  }
  
  .switch-button-case {
    display: inline-block;
    background: none;
    width: 32%;
    height: 100%;
    color: white;
    position: relative;
    border: none;
    -webkit-transition: .3s ease all;
    transition: .3s ease all;
  }
  
  .switch-button-case:hover {
    color: grey;
    cursor: pointer;
  }
  
  .switch-button-case:focus {
    outline: none;
  }
  
  .switch-button .active {
    color: #151515;
    background-color: white;
    position: absolute;
    left: 0;
    top: 0;
    width: 33%;
    height: 100%;
    z-index: -1;
    -webkit-transition: .3s ease-out all;
    transition: .3s ease-out all;
  
    background: -webkit-linear-gradient(0deg, #30bd92, #8360c3);
    background-size: 200%;
  }
  .active_revenue{
    left:0% !important;
  }
  .active_referral{
    left: 34% !important; 
    background-position: right center !important;
  }
  .active_tuf{
    left: 67% !important; 
    background-position: right center !important;
  }
  .switch-button .active-case {
    color: white;
  }
  
  /*SWITCH BUTTON*/
  .dashboard-top {
    display: grid;
    max-width: 98vw;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  
  /*INFORMATION BOXES*/
  .information {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    background-color: var(--readyswap-green);
    padding: 8px 12px;
    border-radius: 12px;
    background: linear-gradient(90deg, #30bd920d, #8360c30d) border-box;
  }
  
  .information:nth-child(1) {
    background: -webkit-linear-gradient(0deg, rgba(48, 189, 147, 0.7), rgba(90, 141, 171, 0.7) 70%);
  }
  
  .information:nth-child(2) {
    background: -webkit-linear-gradient(0deg, rgba(90, 141, 171, 0.7), rgba(131, 96, 195, 0.7) 70%);
  }
  
  .information .text {
    font-size: var(--font-small);
    font-weight: 500;
    opacity: 0.9;
  }
  
  .revenue-sharing {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .referral-rewards {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .dashboard-wrapper {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(10, 1fr);
  }
  
  .dashboard-left {
    display: flex;
    flex-direction: column;
    grid-column: span 6;
    border-radius: 14px;
    padding: 24px 20px;
    background: linear-gradient(270deg, #30bd9217, #8360c326) border-box;
  }
  
  .dashboard-right {
    grid-column: span 4;
    border-radius: 14px;
    padding: 24px 20px;
    background: linear-gradient(270deg, #8360c326, #30bd9217) border-box;
  }
  
  .user-information-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;
    padding-bottom: 4px;
    padding-right: 4px;
    padding-left: 4px;
    font-size: var(--font-small);
    font-weight: 500;
    border-bottom: 1px solid #ffffff3d;
  }
  
  .user-information-row .text {
    opacity: 0.8;
  }
  
  #claim-button {
    border: none;
    background-color: var(--readyswap-green);
    font-size: var(--font-small);
    padding: 4px 20px;
    border-radius: 12px;
    font-weight: 500;
    cursor: pointer;
    color: #e8e8e8;
    width: fit-content;
  }
  
  .tuf-dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;
    gap: 20px;
  }
  
  #tuf-claim-buttom {
    border: none;
    color: white;
    font-weight: 500;
    padding: 4px 20px;
    border-radius: 12px;
    width: fit-content;
    cursor: pointer;
    margin-top: 20px;
    background: -webkit-linear-gradient(0deg, #30bd92, #8360c3);
  }
  .eth-wallet  {
    margin-top: 20px;
  }
  
  .divider {
    margin: 0px auto;
    text-align: center;
    height: 1px;
    max-width: 98vw;
    background: linear-gradient(90deg, hsl(0deg 0% 0% / 0%), hsl(0deg 0% 100% / 46%), hsl(0deg 0% 0% / 0%));
    transition: all .3s;
    margin: 16px 0px;
  }
  
  .claim-history {
    width: 100%;
  }
  
  .claim-history-table {
    background: linear-gradient(transparent, transparent) padding-box, linear-gradient(90deg, #30bd920d, #8360c30d) border-box !important;
    border: 1px solid var(--readyswap-green);
    border-radius: 14px;
    padding: 12px 28px;
    width: 100%;
  }
  
  .table-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  
    background-color: transparent;
    border-bottom: 1px solid #ffffff3d;
    margin-bottom: 8px;
    padding-bottom: 4px;
  }
  
  .table-row.title {
    color: var(--readyswap-green);
    font-weight: 500;
  }
  
  .learn-more .arrow {
    width: 22px;
    filter: invert(46%) sepia(97%) saturate(442%) hue-rotate(122deg) brightness(87%) contrast(87%);
  }
  
  .learn-more {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    color: var(--readyswap-green);
    font-weight: 500;
    position: relative;
    width: fit-content;
  }
  
  .learn-more::after {
    background: var(--readyswap-green);
    bottom: -2px;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    transition: all 0.2s ease-in-out;
    width: 0;
  }
  
  .learn-more:hover:after {
    width: 100%;
  }

  .Toastify__toast-theme--dark{
    background: linear-gradient(270deg, #30bd9217, #8360c326) border-box !important;
  }
  
  @media screen and (max-width:600px) {
  
    .readyswap-dashboard,
    .top-information,
    .referral-rewards,
    .revenue-sharing {
      grid-template-columns: repeat(1, 1fr);
    }
  
    .dashboard-text,
    .referral-rewards {
      grid-column: span 1;
    }
  
    .dashboard-left,
    .dashboard-right {
      grid-column: span 10;
      background: linear-gradient(270deg, #130e1d, #04110d) border-box;
    }
  
    .switch-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  
    .dashboard-top {
      gap: 10px;
      display: flex;
      flex-direction: column;
    }
  
    .information {
      padding: 6px 8px;
    }
  }